import PropTypes from "prop-types";

function Body(props) {
    return (  
       <div className="container">
         <div className="mainBody">
            <h3>Profile</h3>
            <ul>
                <li>{props.fname}</li>
                <li>{props.lname}</li>
                <li>{props.age}</li>
            </ul>
        </div>
       </div>
    );
}

Body.defaultProps = {
    fname:"Olagunju"
}

Body.propTypes = {
    age: PropTypes.number.isRequired,
    fname: PropTypes.string.isRequired
}

export default Body;