import { createContext, useEffect, useState } from "react";
import { v4 } from "uuid";

// - context 
const StudentContext = createContext()

// - provider
export const StudentProvider = ({children})=>{
    const [students,setStudents] = useState([])
    const [isAuthenticated,setIsAuthenticated]= useState(false)
    const [loginMsg,setLoginMsg] = useState('')

    useEffect(()=>{
        dataFetcher();
    },[])

    const dataFetcher = async ()=>{
        const apiUrl = process.env.REACT_APP_API_URL
        const response = await fetch(`${apiUrl}/students`)
        const data = await response.json()

        // console.log(data);
        setStudents(data)
    }

    const studentAddHandler = async (studObj)=> {
        const response = await fetch('/students',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify(studObj)
        })

        const data = await response.json()
        // studObj.id = v4();
        setStudents([data,...students])
    }

    const studentDeleteHandler = async (id)=> {
        if (window.confirm("Are you sure?")) {

            await fetch(`/students/${id}`,{
                method:'DELETE'
            })

            setStudents(students.filter((item)=>{
                return item.id !== id
            } ))
        }
    }

    const [itemEdit,setItemEdit] = useState({
        item:{id:"",fullName:"",classRating:0},
        editMode:false
    })

    const editStudentHandler = (theItem) =>{
        setItemEdit({
            item:theItem,
            editMode:true
        })
        // console.log(theItem);
    }

    const updateStudentHandler = async (id,updItem) => {
        const response = await fetch(`/students/${id}`,{
            method: 'PUT',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify(updItem)
        })

        const data = await response.json()

        setStudents(
            students.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,...data
                    }
                } else {
                    return item
                }
            })
        )
    }

    const updateAuth = (value)=>{
        setIsAuthenticated(value)
    }

    const contextObj = {
        myStud:students,
        studentAddHandler,
        studentDeleteHandler,
        itemEdit,
        editStudentHandler,
        updateStudentHandler,
        isAuthenticated,
        updateAuth,
        loginMsg,
        setLoginMsg
    }


    return <StudentContext.Provider value={contextObj}>
        {children}
    </StudentContext.Provider>
}

export default StudentContext;