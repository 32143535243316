import { Navigate, useNavigate } from "react-router-dom";

function Post() {
    const nav = useNavigate()
    const status = 200

    if (status !== 200) {
       return <Navigate to="/notfound" />
    }
    
    const moveHandler = () => {
        // Do some stuffs here 
        
        return <Navigate to="/notfound" />
        // nav('/notfound')
    }
    
    return <div className="container">
        <h3>Post Page</h3>
        <button onClick={moveHandler} className="btn btn-secondary">Do Redirect</button>
    </div>
}

export default Post;