import Card from "./shared/Card";
import { FaTimesCircle, FaEdit } from "react-icons/fa";
import { useContext } from "react";
import StudentContext from "../context/StudentContext";

function Students({theItem}) {
    const {studentDeleteHandler,editStudentHandler,itemEdit} = useContext(StudentContext)
   
    return <Card reverse={false}>
                {/* <div className="idCircle"> {theItem.id} </div> */}
                <p className="fullnameP">{theItem.firstName} {theItem.lastName}</p>
                <div className="ratingSqr">{theItem.classRating}</div>
                <button onClick={()=>studentDeleteHandler(theItem.id)} className="close">
                    <FaTimesCircle size={"2rem"} />
                    
                </button>
                &nbsp; &nbsp;
                <button onClick={()=>editStudentHandler(theItem)} className="edit">
                    <FaEdit size={"2rem"} />
                    
                </button>
            </Card>
}

export default Students;