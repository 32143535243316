import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Profile() {
    const navigate = useNavigate()
    useEffect(()=>{
        const token = localStorage.getItem('token')
        if (!token) {
            navigate('/login')
        }

    },[])


    return (
        <div className="container">
            <h3>You are logged in</h3>
        </div>
    )
}

export default Profile;