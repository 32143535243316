import { useState,useContext, useEffect } from "react";
import Card from "./shared/Card";
import MainButton from "./shared/MainButton";
import RatingSelector from "./RatingSelector";
import StudentContext from "../context/StudentContext";


function StudentForm() {
    const {studentAddHandler,itemEdit,updateStudentHandler} = useContext(StudentContext)

    const [text,setText] = useState('')
    const [classRating, setClassRating] = useState()
    const [isDisabled,setIsDisabled] = useState(true)
    const [valMessage,setValMessage] = useState('')

    useEffect(()=>{
        // my code
        // console.log('Student Form loaded');
        setText(itemEdit.item.fullName)
        setIsDisabled(false)
    },[itemEdit])

    const textHandler = (e)=>{
        if (text === '') {
            setIsDisabled(true)
            setValMessage(null)
        } else if (text !== '' && text.trim().length <= 5) {
            setIsDisabled(true)
            setValMessage('Character is less than 15')
        }else{
            setIsDisabled(false)
            setValMessage(null)

        }
        setText(e.target.value);
    }

    const studentFormHandler = (e)=>{
        e.preventDefault();
        
        const myObj = {
            fullName:text,
            classRating: classRating
        }

        // console.log(myObj);
        // studentAdd(myObj)
   
        if (itemEdit.editMode === true) {
            updateStudentHandler(itemEdit.item.id,myObj)
        } else {
            
            studentAddHandler(myObj)
        }
        setText('')
    }

    return <div className="container">
        <Card>
       <form onSubmit={studentFormHandler}>
        <h2>Enter a Student with Rating</h2>
        <RatingSelector theRating={(x)=> setClassRating(x)} />
        <div className="input-group">
            <input onChange={textHandler} type="text" value={text} placeholder="Enter your content" />
            <MainButton isDisabled={isDisabled}>Post Submit</MainButton>
        </div>
        {valMessage ? <div>{valMessage}</div>:null }
       </form>
    </Card>
    </div>
}

export default StudentForm;   