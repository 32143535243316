import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useContext } from "react";
import StudentContext from "../context/StudentContext";

function Topbar(props) {
    const {isAuthenticated}  = useContext(StudentContext)
    const [count,setcount] = useState(7)

    const countHandler = () => {
       setcount((ife)=>{
            return ife + 7
       })
    }

    const abtUrl = {
        pathname: '/about',
        search:'?prodid=12&customername=tunji bello'
    }

    const token = localStorage.getItem("token")
    return (
        <div className="container">
           <div className="theTopbar">
               <ul className="mainnav">
                <li>
                    <NavLink activeclassname="active" to="/">Home</NavLink>
                </li>
                <li>
                    <NavLink activeclassname="active" to={abtUrl}>About</NavLink>
                </li>
                <li>
                    <NavLink activeclassname="active" to="/product">Products</NavLink>
                </li>
                <li>
                    <a href="https://www.anchorosftacademy.com" target="_blank">Anchorsoft Academy</a>
                </li>
                 {token
                  ? (<>
                    <li>
                    <a href="https://www.anchorosftacademy.com" target="_blank">Profile</a>
                </li>
                <li>
                    <a href="https://www.anchorosftacademy.com" target="_blank">Log Out</a>
                </li>
                 </>): (<>
                    <li>
                    <NavLink activeclassname="active" to="/login">Log In</NavLink>
                </li>
                <li>
                    <a href="https://www.anchorosftacademy.com" target="_blank">Register</a>
                </li>
                 </>)}
               </ul>
           </div>
        </div>
    )
}

export default Topbar;