import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import StudentContext from "../../context/StudentContext";

function Login() {
    const navigate = useNavigate()
    const {updateAuth,loginMsg,setLoginMsg} = useContext(StudentContext)
    
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')

    const emailHandler = (e)=> {
        setEmail(e.target.value)
    }

    const passwordHandler = (e)=> {
        setPassword(e.target.value)
    }

    const loginHandler = async (e)=>{
        const apiUrl = process.env.REACT_APP_API_URL
        e.preventDefault()
        const response = await fetch(`${apiUrl}/auth`,{
            method:"POST",
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify({
                email,passwordHash:password
            })
        })

        const data = await response.json()
        if (data.access_token) {
            localStorage.setItem("token",data.access_token)
            updateAuth(true)
            navigate('/')
        }else{
            setLoginMsg("Invaid Credentials")
            setEmail('')
            setPassword('')
        }
    }
    
    return <div className="container">
        <h3>Login</h3>
        <br />
        <div className="form">
            <form onSubmit={loginHandler}>
                <span style={{color:'red',fontWeight:'bold'}}>{loginMsg}</span>
                <div className="form-group">
                    <input onChange={emailHandler} value={email} name="email" id="email" placeholder="Your Email" className="input-group" type="text" />
                </div>
                <br />
                <div className="form-group">
                    <input onChange={passwordHandler} name="password" id="password" placeholder="Your Password" className="input-group" type="password" />
                </div>
                <br />
                <button type="submit" className="btn btn-secondary">Login</button>
            </form>
        </div>
    </div>
}

export default Login;