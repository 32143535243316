import Topbar from "./components/Topbar";
import Body from "./components/Body";
import StudentsList from "./components/StudentsList";
import StudentsSummary from "./components/StudentsSummary";
import StudentForm from "./components/StudentForm";
import About from "./components/pages/About";
import Product from "./components/pages/Product";
import Post from "./components/pages/Post";
import { BrowserRouter, Routes,Route } from "react-router-dom";
import NotFound from "./components/pages/NotFound";
import { StudentProvider } from "./context/StudentContext";
import Login from "./components/pages/Login";
import Profile from "./components/pages/Profile";

function App() {
    return (
       <StudentProvider>
       <BrowserRouter>
        <Topbar title={"Title from App"} content="This is content" />
        <Routes>
            <Route path="/" element={
                <>
                <StudentForm  />
                <StudentsSummary />
                <StudentsList />
                <Body  lname="Owolabi" age={12} /> 
                </>
            } />
            <Route path="/about" element={<About />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/post" element={<Post />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/notfound" element={<NotFound   />} />
        </Routes>
       </BrowserRouter>
       </StudentProvider>
    )
}
export default App;